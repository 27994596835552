<template>
  <MDBBtn color="secondary" @click="handleLogin">Log In</MDBBtn>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit";
import { useAuth0 } from "@auth0/auth0-vue";
const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: "/home",
    },
  });
};
</script>
