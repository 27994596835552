<template>
  <NavBar />
  <MDBContainer>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      Hello {{ user.email }}! Hotliner's web interface is currently under construction. To get started with Hotliner, send an email to team@wireshout.com. Thanks for your interest!
      <ApiTest />
    </div>
  </MDBContainer>
  <FooterElem />
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";
import ApiTest from "@/components/buttons/ApiTest.vue";
import NavBar from "@/components/NavBar.vue";
import FooterElem from "@/components/FooterElem.vue";

export default {
  name: "HomePage",
  components: {
    MDBContainer,
    NavBar,
    FooterElem
  },
};
</script>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { user } = useAuth0();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
