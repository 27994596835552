<template>
  <div v-if="isLoading" class="page-layout">
    <PageLoader />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  methods: {
  },
}
</script>

<script setup>
import PageLoader from "@/components/PageLoader.vue";
import { useAuth0 } from "@auth0/auth0-vue";
const { isLoading } = useAuth0();
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
