<template>
  <MDBFooter :text="['center', 'lg-start']" class="fixed-bottom">
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
      © 2023  
      <a class="text-dark" href="https://hotliner.me/">hotliner by WireShout</a>
    </div>
  </MDBFooter>
</template>

<script>
import { MDBFooter } from "mdb-vue-ui-kit";

export default {
  name: "FooterElem",
  components: {
    MDBFooter,
  },
};
</script>