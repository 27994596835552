import { auth0 } from 'src/auth0.js';
import { createApp } from 'vue';
import App from './App.vue';
import 'mdb-vue-ui-kit/css/mdb.min.css';
import router from "./router";

const app = createApp(App);

app
  .use(router)
  .use(auth0);

app.mount('#app');
