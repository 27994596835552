<template>
  <MDBBtn color="primary" @click="handleSignUp">Sign Up</MDBBtn>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit";
import { useAuth0 } from "@auth0/auth0-vue";
const { loginWithRedirect } = useAuth0();

const handleSignUp = () => {
  loginWithRedirect({
    appState: {
      target: "/home",
    },
    authorizationParams: {
      screen_hint: "signup",
    }
  });
};
</script>
