<template>
  <MDBNavbar light bg="light" container>
    <MDBNavbarBrand href="#">
      <img
        src="../assets/logo.png"
        height="30"
        alt=""
        loading="lazy"
      />
    </MDBNavbarBrand>
    <span class="navbar-text">
      {{ user.email }}
    </span>
    <LogoutButton />
  </MDBNavbar>
</template>

<script>
import { MDBNavbar, MDBNavbarBrand } from 'mdb-vue-ui-kit';
import LogoutButton from "@/components/buttons/Auth0LogoutButton.vue";

export default {
  name: "NavBar",
  components: {
    MDBNavbar,
    MDBNavbarBrand,
    LogoutButton,
  },
};
</script>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { user } = useAuth0();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
