<template>
  <MDBBtn color="secondary" @click="handleLogout">Log Out</MDBBtn>
</template>

<script setup>
import { MDBBtn } from "mdb-vue-ui-kit";
import { useAuth0 } from "@auth0/auth0-vue";
const { logout } = useAuth0();

const handleLogout = () =>
  logout({
    logoutParams: {
      returnTo: window.location.origin,
    }
  });
</script>
