import { authGuard } from "@auth0/auth0-vue";
import { createRouter, createWebHistory } from "vue-router";

import LoginPage from "@/components/LoginPage.vue";
import HomePage from "@/components/HomePage.vue";

const routes = [
    {
      path: "/",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/home",
      name: "home",
      component: HomePage,
      beforeEnter: authGuard,
    },
    // {
    //   path: "*",
    //   name: "Not Found",
    //   component: NotFoundPage,
    // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
