// This implementation of Auth0 is based around this solution:
// https://github.com/auth0/auth0-vue/issues/99#issuecomment-1480769493

import { createAuth0 } from '@auth0/auth0-vue';

export const auth0 = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    scope: 'openid profile email admin:teams',
    audience: process.env.VUE_APP_AUTH0_API_AUDIENCE
  }
});
