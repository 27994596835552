<template>
  <MDBBtn color="secondary" @click="apiTest">Test API</MDBBtn>
</template>

<script>
import { MDBBtn } from "mdb-vue-ui-kit";
import { auth0 } from 'src/auth0.js';
import axios from 'axios';

export default {
  name: "ApiTest",

  components: {
    MDBBtn
  },

  setup() {
  },

  data() {
    return {
      token: ''
    }
  },

  async mounted() {
    await this.cacheAccessToken();
  },

  methods: {
    async cacheAccessToken() {
      this.token = await auth0.getAccessTokenSilently();
    },

    async apiTest() {
      axios.get(process.env.VUE_APP_AUTH0_API_AUDIENCE + "v3/team", {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
        .then(response => this.totalVuePackages = response.data.total)
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    }
  }
}
</script>
