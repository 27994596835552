<template>
  <MDBContainer>
    <div
      class="d-flex justify-content-center align-items-center"
    >
      <img
        src="../assets/full-logo.png"
        height="200"
        alt=""
        loading="lazy"
      />
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
    >
      <SignupButton />
      <LoginButton />
    </div>
    <FooterElem />
  </MDBContainer>
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";
import LoginButton from "@/components/buttons/Auth0LoginButton.vue";
import SignupButton from "@/components/buttons/Auth0SignupButton.vue";
import FooterElem from "@/components/FooterElem.vue";

export default {
  name: "LoginPage",
  components: {
    MDBContainer,
    LoginButton,
    SignupButton,
    FooterElem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
